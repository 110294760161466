// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$Jupiter-primary: mat.define-palette(mat.$indigo-palette);
$Jupiter-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$Jupiter-warn: mat.define-palette(mat.$red-palette);

// Create the theme object with color, typography, and density configurations
$Jupiter-theme: mat.define-light-theme((
  color: (
    primary: $Jupiter-primary,
    accent: $Jupiter-accent,
    warn: $Jupiter-warn,
  )
));
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-legacy-component-themes($Jupiter-theme);
@include mat.all-component-themes($Jupiter-theme);

/* You can add global styles to this file, and also import other style files */

//Include theme styles for editor
@import "~quill/dist/quill.snow.css";
//
// style for ng-select
@import "~@ng-select/ng-select/themes/default.theme.css";


html,
body {
  height: 100%;
}

body {
  overflow-y: scroll;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.primaryToolbar {
  height: 60px;
  width: 100%;
  align-items: center;
  padding: 5px 10px;
}

.secondaryToolbar {
  height: 40px;
  width: 100%;
  background-color: #1830a3;
  color: ghostwhite;
  padding: 10px 10px;
}

.toolbarHeaderIcon {
  height: 30px;
  width: 30px;
  margin: 5px;
}

.toolbarSubheaderIcon {
  margin-top: 10px;
}

.wrapper {
  margin-top: 12px;
  min-width: 150px;
  text-align: center;
}

.cdk-global-overlay-wrapper:not(:has(.metadata-dialog)),
.cdk-overlay-container:not(:has(.metadata-dialog-backdrop)) {
  z-index: 99999 !important;
}

table {
  line-height: 15px !important;
}

.table thead th {
  vertical-align: middle;
}

body {
  line-height: 1rem;
}

.modal-backdrop {
  z-index: -1;
}

.mat-snack-bar-container {
  color: #ffffff !important;
}

.mat-simple-snackbar {
  font-size: initial !important;
}

.snackbarWarning {
  //background-color: #ffa500 !important;
  background-color: #f7931a !important;
}

.snackbarError {
  // background-color: #f44336 !important;
  background-color: #d32a36 !important;
}

.snackbarSuccess {
  background-color: #00d45a !important;
}

.full-width {
  width: 100%;
}

// .metadata-dialog-backdrop {
//   [dir="rtl"] & {
//     z-index: auto;
//   }
// }

button.mat-calendar-body-cell {
  position: absolute;
  height: 100%;
  }
  
  td.mat-calendar-body-cell {
  position: relative;
  height: 0;
  }
  
  .mat-calendar-arrow {
  width:0 !important;
  }

  .cdk-overlay-container{
    z-index: 1050;
  }